    .loginFlexBox {

        .listview {
            margin: 3.2em 0.55em;
            background: none;
        }

        .loginBanner {
            margin-bottom: 30px;
            margin-top: 40px;
            display: inline-block;
            font-size: 19px;

            .projektron {
                padding-left: 5px;
                text-transform: uppercase;
                color: var(--loginTitelColor);
            }

            .bcs {
                padding-left: 5px;
                font-weight: 700;
                color: var(--loginTitelColor);
            }

            .image {
                width: 60px;
                height: 60px;
                border: 0;
                border: none;
                outline: none;
                display: inline-block;
                vertical-align: -8px;
            }

            span.beta {
                font-size: 14px;
                margin-top: 35px;
                margin-left: 5px;
                position: absolute;
                color: #7b3232;
                font-weight: 600;
            }
        }

        .usernameRow {

            margin-bottom: 10px;


            input {
                color: var(--loginFieldColor);
                background-color: var(--form-background-color) !important;
                border: none;
                border-bottom: 1px solid var(--form-border-color);
                padding-left: 5px;
                /*+5px Weil wir den InputFeldern jeweils ein Padding von 5px gegeben haben, dies macht diese 5px Breiter, daher gleichen wir dies hier aus.*/
                width: calc(~"100% - 5px");
            }

            input:active, input:focus {
                background-color: white;
                border: none;
                border-bottom: 2px solid #32A0E6;
            }
        }


        .offlineOnlineRow {

            margin-bottom: 10px;
            display: none !important;

            input {
                color: var(--loginFieldColor);
                background-color: var(--form-background-color) !important;
                border: none;
                border-bottom: 1px solid var(--form-border-color);
                padding-top: 5px;
                padding-left: 5px;
                padding-bottom: 5px;
                /*+5px Weil wir den InputFeldern jeweils ein Padding von 5px gegeben haben, dies macht diese 5px Breiter, daher gleichen wir dies hier aus.*/
                width: calc(~"100% - 5px");
                border-radius: 0px;
            }

            input:active, input:focus {
                background-color: white;
                border: none;
                border-bottom: 2px solid #32A0E6;
            }

        }

        .passwordRow {

            margin-bottom: 10px;

            input {
                color: var(--form-color);
                background-color: var(--form-background-color) !important;
                border: none;
                border-bottom: 1px solid var(--form-border-color) !important;
                padding-left: 5px;
                border-radius: 0px;
                /*+5px Weil wir den InputFeldern jeweils ein Padding von 5px gegeben haben, dies macht diese 5px Breiter, daher gleichen wir dies hier aus.*/
                width: calc(~"100% - 5px");
            }

            input:active, input:focus {
                background-color: white;
                border: none;
                border-bottom: 1px solid #32A0E6;
            }

        }

        .formbutton {

            color: white !important;
            border: 1px solid #32a0e6 !important;
            font-size: 16px;
            font-family: "Segoe UI", "Frutiger", Helvetica, "Helvetica Neue", Tahoma, Verdana, Arial, sans-serif;
            min-width: 100px;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAgCAYAAAAv8DnQAAABIklEQVQ4EZVSCY4DIQyDER/bR/d5JWs7DpO2q0qLRhPjHDiB+fOIGAMfFtGcabknXkHWAaAQxD08XIAKYKSWStDBoEy7ZHqR9DkgxmKZGFtElmaES0oD3NNEGNNyTZhFUMQHZkBs/O+Kyu2aIfLLkga0dDIcmwp6m+Wwh2PQSGBTpAfDuAYH5XkOTnWlbq6IGlKnb7yYe0Si/sTF5IVl0B8a2nFIUEA2ZOUoV0JZ+ft7YEA2nOcd7BI87NJFEdXRZUX9+z28tSmR/FXf+aI5C5DiIXJj4J0oR1m0iVGHU1QOLm8pHC9qw29GrwuY1isHVaMj2TG2OqK4FIkTXJA8RN7XrZuH87wA4LWfW1fsI+E9SDi7sGzO4+M9kKxBMfcVj/ELI1PTxuT7jsEAAAAASUVORK5CYII=) center center #32A0E6 !important;
            margin: 0px 1px;
            padding: 5px 4px 10px 4px;
            height: 34px;
            border-radius: 4px 4px 4px 4px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            font-weight: normal;

            width: 100%;
        }

        .authSeparator {
            flex-grow: 1;
            display: block;
            margin-top: 40px;
            color: var(--border-color);
        }        
    }

