.defaultListView {

	label {
		display: block;
	}

	input.formfield {
		box-sizing: border-box;
		width: 100%;
		display: block;
		font-family: Roboto,"Segoe UI","Frutiger",Helvetica,"Helvetica Neue",Tahoma,Verdana,Arial,sans-serif;
		font-size: 17px;
	}

	.formfield::placeholder { 
		font-style: oblique;
	}

	input[type='date'].formfield, input[type='time'].formfield, input[type='datetime-local'].formfield {
        width: auto;
	}

	input.displayMode {
		background-color: inherit;
		border-bottom: none; 
	}

    /* Label hier per Default dispay=block, aber Label hinter Checkbox in selber Zeile */
    label.formcheckbox {
        display: inline;
        padding-left: 0.5em;
        font-size: 17px;
    }

	textarea.formfield {
		box-sizing: border-box;
		padding: 5px 5px 5px 5px;
		width: 100%;
		display: block;
		border: none;
		border-bottom: 1px solid var(--form-border-color);
		background: var(--form-background-color);
	}

	input.formbutton {
		box-sizing: border-box;
		width: 100%;
		display: block;
	}

    input.bcsDateField {
        width: auto;
    }

	input.bcsDateField.formbutton {
		box-sizing: border-box;
		width: auto;
		text-align: center;
		cursor: pointer;
		box-sizing: border-box;
		padding: 6px 5px 6px 5px;
		border-style: none none solid none;
		border-bottom: 1px solid var(--form-border-color);
		font-family: Roboto, "Segoe UI", "Frutiger", Helvetica, "Helvetica Neue", Tahoma, Verdana, Arial, sans-serif;
	}

    div.dateTime_TimeField {
        margin-left: 1em;
    }

	select.formfield {
		box-sizing: border-box;
		padding: 5px 5px 5px 5px;
		width: 100%;
		border: none;
		border: none;
		border-bottom: 1px solid var(--form-border-color);
		background: var(--form-background-color);
		/*border-width: 1px;
		border-color: #C3C5C9;
		background-color: inherit;*/
	}

	select.formfield:focus, select.formfield:active {
		border: none;
		padding: 5px 5px 5px 5px;
    }
}

.fixedCalendarWrapper {
	position: fixed;
	width: 100%;
	bottom: calc(61px + var(--bottom-offset));
	background-color: var(--FooterToolbar-Background);
	box-shadow: 0px -3px 9px -2px rgba(0, 0, 0, 0.6);
	z-index: 10;
}
