/**
Variablen und allgemeine Styles für die App
 */
:root{
    /** Navigation */
    --NavigationHeader-Color: #CCD8E0; /* Alternativ #E3EAF3 */
    --FooterToolbar-Background: #ffffff;
    --FooterToolbar-Border: #E9F0F9;
    --NavigationTitle-Color: var(--page-color);
    --bottom-offset: 0px; // Ofset für den Footer (Wird bei iOS Geräten mit Strich gesetzt)
    /* Schriftgröße der Labels unter den Icons im Footer */
    --footerIcon-font-size: 12px;

    // TimeRecording und Restaufwandsschätzung
    --timeRecording_base_color: #3FA6E7;
    --timeRecording_isOnlyChangedInApp_Color: #e5aa21;
    --timeRecording_isOnlyChangedInApp_Background_Color: #887212;
    --timeRecordingPSPPath-line-height: inherit;


    --booking-TextColor: var(--page-secondary-color);
    --pause-TextColor: var(--greyBaseColor);
    --booking-BackgroundColor: #BADDF8;
    --pause-BackgroundColor: #DEE2E9;
    --dummy-BackgroundColor: #FDDFFF;
    --dummy-TextColor: var(--page-color);
    --placeholderBooking-BackgroundColor: #D6DAE4;
    --placeholderBooking-TextColor: var(--page-color);
    --synchedBooking-BackgroundColor: #E5CF70;
    --synchedBooking-TextColor: #BC7F1C;

    /// Tacho und Füllbalken
    --TimeRecording_BookedTimeColor: #3FA6E7;
    --TimeRecording_BookedTimeColor_light: #B9DEF6;
    --TimeRecording_UnbookedTimeColor: #E6EAF0;
    --TimeRecording_UnbookedTimeColor_dark: #ABAFB8;
    --TimeRecording_AlertColor: #D0021B;

    --Calendar-TodayBackground : #D3DFDD;

    /* Kontakterfassung */
    --contactBoxColor: #45703B;


    /* Login */
    --loginFieldColor: #1e1e21;
    --loginTitelColor: var(--page-color);

    /** Standard-Schriftfarbe */
    --page-color: #000000;

    /** Standard-Schriftfarbe abgedunkelt */
    --page-secondary-color: #767676;

    /** Farbe für Labels */
    --form-label-color: #72718b; /*rgba(46,50,53,0.7);*/
    //Spesenerfassung
    --allowance_base_color: #9D476C;
    --travelSection-Background: #E9F0F9;

    --greyBaseColor: #E6EAF0;
    --mainBackgroundColor: #EBEFF3;

    --PrimaryElement-Color : #ffffff;
    /* Schriftfarbe in Formularfeldern */
    --form-color: #000000;
    /* Randfarbe von Formularfeldern */
    --form-border-color: #B1C8D8;
    /* Hintergrundfarbe von Formularfeldern */
    --form-background-color: #f0f9ff;

    /* ListView */
    --clickActiv-Color: #67bcec3b;
    --defaultListView-Background: var(--PrimaryElement-Color);
    --ListView-Title: #7D8694;
    --ListView-Border: 1px solid #DDE3ED;
    --ListView-FormField: var(--page-color);
    --ListView-TableData: var(--page-color);
    --ListView-FieldFont: #8993A1;

    /* Progress Layer */
    --ProgressLayer-Font: var(--page-color);
    --ProgressLayer-Background: var(--page-secondary-color); /* Alternativ #535353; */

    /*Floating Button*/
    --FloatingButton-Background: #32A0E6;
    --FloatingButton-Color: #FBFBFB;

    /* Sub Menu */
    --subMenuBackground : white;
    --subMenu-Border : #DFE5EF;

    /** Separator-Farbe */
    --border-color: #dbdbdb;

    /** Tabellen-Randfarbe */
    --table-border-color: #F2F2F2;
    
}

/* Spezialkram für chinesische Sprache */
:root[lang=zh-CN] {
    letter-spacing: 1px;

    --footerIcon-font-size: 16px;

    --timeRecordingPSPPath-line-height: 14.5px;
}

/* Für iPhones mit Strich am unteren Rand (ab iPhone X / ausgenommen iPhone SE2): Platz unter Footer wegen Strich des Betriebssystems */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3), only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2), only screen and(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3), only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) {
    :root {
        --bottom-offset: 1.2em;
    }
}

    @font-face {
        font-family: Roboto;
        src: local('Roboto'), url('../fonts/Roboto-Regular.woff');
        font-display: swap;
    }

    @font-face {
        font-family: Roboto;
        font-weight: 600;
        src: local('Roboto Bold'), url('../fonts/Roboto-Medium.woff');
        font-display: swap;
    }

    @font-face {
        font-family: Roboto;
        font-weight: bold;
        src: local('Roboto Bold'), url('../fonts/Roboto-Medium.woff');
        font-display: swap;
    }

    html {
        /* Verhindert zusätzlich zu meta name="viewport" content="width=device-width" die 300ms Wartezeit nach onclick */
        /* touch-action: manipulation; */
        overscroll-behavior: none;
    }

    body {
        /*----------------BROWSER EINSTELLUNGS RESET - START---------------------*/

        input,
        input:hover,
        input:active,
        textarea,
        textarea:hover,
        textarea:active,
        select,
        select:hover,
        select:active {
            /*Entfernen alle Browser voreinstellungen beim Focussieren eines Input Feldes*/
            outline: none;
        }

        /* Change the white to any color ;) */

        input:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px var(--form-background-color) inset;
        }

        /* Spinner entfernen */

        input[type=time]::-webkit-inner-spin-button,
        input[type=time]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Uhrzeit Picker entfernen (Chrome 83+) */

        input[type="time"]::-webkit-calendar-picker-indicator {
            display: none;
        }

        /* Chrome/Safari Clear Button entfernen */

        input[type="time"]::-webkit-clear-button {
            display: none;
        }


        /*----------------BROWSER EINSTELLUNGS RESET - ENDE---------------------*/

        ::-webkit-input-placeholder {
            color: #AAAAAA;
            opacity: 1;
            font-style: oblique;
            text-overflow: ellipsis !important;
        }

        ::-moz-placeholder {
            color: #AAAAAA;
            opacity: 1;
            font-style: oblique;
            text-overflow: ellipsis;
        }

        ::placeholder {
            color: #AAAAAA;
            opacity: 1;
            font-style: oblique;
            text-overflow: ellipsis !important;
        }

        input[type=text].displayMode {
            border: 0px solid transparent;
            padding-left: 0;
        }

        input[type=text]:not(.displayMode),
        input[type=search],
        input[type=number],
        input[type=password] {
            border-radius: 0;
            font-size: 17px;
            font-family: Roboto;
            padding: 4px 3px 3px 3px !important;
            height: 30px;
            border: none;
            border-bottom: 1px solid var(--form-border-color) !important;
            color: var(--form-color);
            background: var(--form-background-color);
        }

        input[type=number] {
            -webkit-appearance: none;
            appearance: none;
        }


        input[type=search] {
            -webkit-appearance: none;
            appearance: none;
            height: 35px;
            padding-left: 31px !important;
        }

        input.bcsDateField:focus,
        input.bcsDateField:active,
        input[type=time]:focus,
        input[type=time]:active,
        input[type=number]:focus,
        input[type=number]:active,
        input[type=password]:focus,
        input[type=password]:active,
        select:focus,
        select:active,
        textarea:focus,
        textarea:active,
        input[type=text]:focus:not(.displayMode),
        input[type=text]:active:not(.displayMode),
        input[type=search]:focus,
        input[type=search]:active {
            border-bottom: 1px solid #32A0E6 !important;
        }


        input.bcsDateField,
        input[type=time],
        select {
            font-family: Roboto;
            font-size: 17px;
            -webkit-appearance: none;
            appearance: none;
            background-image: url(../images/buttons/dropdown@1x.svg) !important;
            background-position: 100% 6px !important;
            background-repeat: no-repeat !important;
            padding: 4px 18px 3px 3px !important;
            line-height: 19px;
            height: 30px;
            border-radius: 0;
            border: none;
            border-bottom: 1px solid var(--form-border-color) !important;
            color: var(--form-color);
            background: var(--form-background-color);
        }

        input.bcsDateField {
            font-feature-settings: "pnum"1;
        }

        input[type=time] {
            font-feature-settings: "pnum"1;
            padding-top: 5px !important;
            width: 77px !important;
            padding-right: 0 !important;
        }

        .durationField input[type=time] {
            background-image: url(../images/buttons/hours@1x.svg) !important;
            background-position: 100% 5px !important;
        }

        .longDurationField input[type=time] {
            background-image: url(../images/buttons/hours@1x.svg) !important;
            background-position: 100% 5px !important;
        }

        .longDurationField input[type=number] {
            width: 45px !important;
            background-image: url(../images/buttons/days@1x.svg) !important;
            background-position: 100% 5px !important;
            background-repeat: no-repeat !important;
        }


        textarea {
            font-family: Roboto;
            font-size: 17px;
            border-radius: 0;
        }

        textarea:focus {
            border-bottom: 1px solid #32A0E6;
        }


        input[type=checkbox] {
            width: 18px;
            height: 18px;
            -webkit-appearance: none;
            border: 1px solid var(--form-border-color);
            background-color: var(--form-background-color);
            border-radius: 2px;
            background-position: center center;
            background-repeat: no-repeat;
            vertical-align: text-top;
        }

        input[type=checkbox]:checked {
            background-image: url(../images/buttons/checkbox-checked@1x.svg);
        }

        input[type=checkbox]:disabled {
            opacity: 0.7;
        }

        color: var(--page-color);
        background-color: var(--mainBackgroundColor);
        margin: 0px;
        font-family: Roboto,
        "Segoe UI",
        "Frutiger",
        Helvetica,
        "Helvetica Neue",
        Tahoma,
        Verdana,
        Arial,
        sans-serif;

        /* Verhindert Neuladen der Seite beim Scrollen über oberen Seitenanfang hinaus */
        overscroll-behavior: none;

        .hidden {
            display: none;
        }


        .button {
            font-size: 15px;
            margin: 0 2px 1px 2px;
            line-height: 34px;
            padding: 6px;
            background-position: center center;
            background-color: #F4F4F4;
            vertical-align: middle;
            border: 1px solid #DDDDDD;
            border-radius: 4px 4px 4px 4px;
        }

        .button.activ {
            color: white !important;
            border-color: #32a0e6 !important;
            background-color: #32A0E6 !important;
        }
    }

    body.defaultBackground {
        background-color: var(--mainBackgroundColor);
    }

    .boardView {
        background-color: var(--mainBackgroundColor);
        width: 100%;
        height: 100%;
        position: relative;

        .boardElementWrapper {
            background-color: var(--mainBackgroundColor);
            position: absolute;

            .boardElement {
                background-color: var(--PrimaryElement-Color);
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                overflow: hidden;

                .controllerLink {
                    height: 100%;
                    width: 100%;
                }

                p {
                    margin: 0;
                    text-align: center;
                    margin-top: 5px;
                }


            }

            .boardElement.overviewCalendar {
                height: 315px !important;
                padding-top: 3px;
            }

            .boardElement.linkToAllowance {
                color: var(--allowance_base_color);
                font-size: 14px;
            }

            .boardElement.allowanceLink {
                color:var(--allowance_base_colorwance_base_color);
            }

            .boardElement.lastAllowanceDetais {
                color: var(--allowance_base_color);
            }

            .boardElement.currentTimeRecordingDetails {
                text-align: center;
                color: var(--timeRecording_base_color);
            }

            .boardElement.linkToAllowance:active,
            .boardElement.allowanceLink:active,
            .boardElement.lastAllowanceDetais:active,
            .boardElement.currentTimeRecordingDetails:active {
                background-color: #67bcec3b;
            }

        }
    }

    #sync_top_toolbar {
        width: 100%;
        background-color: #F5A623;
        color: white;

        .toolLink {
            padding: 5px;
            border-radius: 5px;
        }
    }

    /* Fehlermeldung u.a. vor Starten der App (z.B. kein HTTPS, Browser nicht unterstützt) */
    .errorMessageBox {

        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        .errorBox {
            color: black;
            border: 5px solid #F87373;
            border-radius: 10px;
            width: 70%;
            padding: 1em 1em 1em 1em;

            .title {
                color: #2F3A96;
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: 1em;
            }
        }
    }

    .durationField {
        white-space: nowrap;
        color: #7D8694;
        margin-right: 3px;
        line-height: 19px;
        vertical-align: text-bottom;

        input {
            display: inline-block !important;
            margin-right: 2px;
        }
    }

    .longDurationField {
        white-space: nowrap;
        color: #7D8694;
        margin-right: 3px;
        line-height: 19px;
        vertical-align: text-bottom;
        display: inline-flex;

        input {
            display: inline-block !important;
            margin-right: 0px;
        }
    }

    .serviceworkerupdate {

        box-sizing: border-box;
        position: absolute;
        z-index: 99;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 8em;
        color: black;
        font-weight: bold;
        padding: 0.5em;
        background-color: #ECA926;
        border-radius: 5px;
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);

        .row {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 0.5em;
        }

        .message {
            display: block;
            align-self: center;
        }

        a.reloadNow {
            display: block;
            align-self: center;
            color: #1E5583;
            text-decoration: none;
            background-color: white;
            padding: 10px;
            border-radius: 10px;
            min-width: 100px;
            text-align: center;
        }

        a.skip {
            display: block;
            align-self: center;
            color: #1E5583;
            text-decoration: none;
            background-color: white;
            padding: 10px;
            border-radius: 10px;
            min-width: 100px;
            text-align: center;
        }
    }

    // blendet Aufgaben in der Aufgabenliste aus, die nur ein Projekt, aber kein Unterprojekt �ber sich haben (also keinen parentName, nur einen grandParentName Value gesetzt)
    tr.superContent:has([name="parentName"]):has([value='']) {
        display: none;
    }
    // wir r�cken das Orga-Icon in der Aufgabenliste mehr ein, damit es schicker ausssieht und gleichen den Namen des Unterprojektes an
    .fieldSmallFont{
        margin-left: 12px;
    }
    .ouTasklist{
        margin-left: 36px;
        
        img {
            max-height: 20px;
        }
    }
    // gibt es aber keine Orga an der Aufgabe (warum auch immer...) wollen wir das Icon nat�rlich auch nicht sehen, bzw die ganze TR loswerden (dann wird die Liste kompakter)
    tr.subContent:has([name="customer.name"]):has([value='']) {
        display: none;
    }